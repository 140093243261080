import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface BasicButtonProps {
  text: string;
  hoverText?: string;
  onClick?: () => void;
  bgColor?: string;
  hoverBgColor?: string;
  className?: string;
  icon?: IconProp;
  iconPosition?: 'left' | 'right';
  textColor?: string;
  fontWeight?: string;
  borderColor?: string;
  borderWeight?: string;
  disabled?: boolean;
}

const StyledButton = styled.button<{
  bgColor?: string;
  hoverBgColor?: string;
  textColor?: string;
  fontWeight?: string;
  borderColor?: string;
  borderWeight?: string;
  hasHoverText?: boolean;
}>`
  position: relative;
  border: ${(props) => props.borderWeight || '1.5px'} solid
    ${(props) => props.borderColor || 'rgb(35, 35, 35)'};
  box-shadow: 3px 3px rgba(0, 0, 0, 0.6);
  border-radius: 1px;
  background-color: ${(props) => props.bgColor || '#60fac1e1'};
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.textColor || 'rgb(35, 35, 35)'};
  font-size: 1rem;
  font-weight: ${(props) => props.fontWeight || 500};
  transition-duration: 0.4s;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 44px;
  min-width: 60px;

  &:hover {
    background-color: ${(props) =>
    props.hoverBgColor || props.bgColor || '#1b8e64e1'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
  }

  .text-content,
  .hover-text-content {
    display: inline-block;
  }

  .hover-text-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
  }

  /* Conditionally hide/show text based on the presence of hoverText */
  &:hover .hover-text-content {
    visibility: ${(props) => (props.hasHoverText ? 'visible' : 'hidden')};
  }

  &:hover .text-content {
    visibility: ${(props) => (props.hasHoverText ? 'hidden' : 'visible')};
  }
`;

const BasicButton: React.FC<BasicButtonProps> = ({
  text,
  hoverText,
  onClick,
  bgColor,
  hoverBgColor,
  className,
  icon,
  iconPosition = 'left',
  textColor,
  fontWeight,
  borderColor,
  borderWeight,
  disabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Ensure hoverText is not longer than text and not the same
  const adjustedHoverText =
    hoverText && hoverText.length <= text.length && hoverText !== text
      ? hoverText
      : undefined;

  const hasHoverText = Boolean(adjustedHoverText);

  return (

    <StyledButton
      onClick={onClick}
      bgColor={bgColor}
      hoverBgColor={hoverBgColor}
      textColor={textColor}
      fontWeight={fontWeight}
      borderColor={borderColor}
      borderWeight={borderWeight}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={className}
      hasHoverText={hasHoverText} // Pass hasHoverText to StyledButton
      disabled={disabled}
    >
      <div className=' '>
        {icon && iconPosition === 'left' && (
          <FontAwesomeIcon
            icon={icon}
            className={`${text ? 'pr-2' : ''} align-middle`}
          />
        )}

        <span className="text-content">{text}</span>

        {hasHoverText && (
          <span className="hover-text-content">
            {isHovered ? adjustedHoverText : ''}
          </span>
        )}

        {icon && iconPosition === 'right' && (
          <FontAwesomeIcon
            icon={icon}
            className={`${text ? 'pl-2' : ''} align-middle`}
          />
        )}
      </div>
    </StyledButton >

  );
};

export default BasicButton;
