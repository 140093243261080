import React from 'react';

interface EmojisBoxProps {
    emojis: string[]; // define a prop for the list of emojis
}

const EmojisBox: React.FC<EmojisBoxProps> = ({ emojis }) => {

    if (emojis.length === 0) {
        return null;
    }

    return (
        <div className="absolute text-lg bottom-[-2px] right-[-3px] bg-gray-100 p-0.5 px-1 flex gap-1 shadow-indigo-400 shadow-sm rounded-sm">
            {emojis.slice(0, 3).map((emoji, index) => (
                <span key={index} role="img" aria-label={`emoji-${index}`}>{emoji}</span>
            ))}
        </div>
    );
};

export default EmojisBox;