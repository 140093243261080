import React, { useState } from "react";
import axios from "axios";
import BasicButton from "../basics/BasicButton";
import "./aotd.css";
import { setHeaders, url } from "../../api";
import { toast } from "react-toastify";
import emojiRegex from 'emoji-regex';

interface NextAOTDProps {
    nextEmojis: string[];
}

const singleEmojiRegex = new RegExp(`^${emojiRegex().source}$`);

const NextAOTD: React.FC<NextAOTDProps> = ({ nextEmojis }) => {
    const [emojis, setEmojis] = useState(["", "", ""]);
    const [inputError, setInputError] = useState([false, false, false]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [emojiSet, setEmojiSet] = useState(nextEmojis.length > 0);
    const [nnextEmojis, setnextEmojis] = useState(nextEmojis);

    const handleEmojiChange = (index: number, value: string) => {
        if (value && !singleEmojiRegex.test(value)) {
            triggerInputError(index);
            return;
        }
        updateEmojis(index, value);
        focusNextInput(index, value);
    };

    const triggerInputError = (index: number) => {
        const newInputError = [...inputError];
        newInputError[index] = true;
        setInputError(newInputError);
        setTimeout(() => {
            newInputError[index] = false;
            setInputError(newInputError);
        }, 500);
    };

    const updateEmojis = (index: number, value: string) => {
        const newEmojis = [...emojis];
        newEmojis[index] = value;
        setEmojis(newEmojis);
    };

    const focusNextInput = (index: number, value: string) => {
        if (value && index < emojis.length - 1) {
            const nextInput = document.getElementById(`emoji-input-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleSubmit = async () => {
        const filteredEmojis = emojis.filter((e) => e.trim() !== "");
        if (!areAllEmojisValid(filteredEmojis)) {
            setErrorMessage("Please enter valid single emojis only.");
            return;
        }
        await submitEmojis(filteredEmojis);
    };

    const areAllEmojisValid = (emojis: string[]) => {
        return emojis.every((emoji) => singleEmojiRegex.test(emoji));
    };

    const submitEmojis = async (filteredEmojis: string[]) => {
        try {
            const headers = setHeaders();
            const response = await axios.post(
                url + "/api/art-of-the-day/set-next-aotd-emojis",
                { emojis: filteredEmojis },
                headers
            );

            if (response.status === 200) {
                toast.success("Emojis updated successfully.");
                setErrorMessage(null);
                setEmojiSet(true);
                setnextEmojis(filteredEmojis);
            }
        } catch (error: any) {
            handleSubmissionError(error);
        }
    };

    const handleSubmissionError = (error: any) => {
        if (error.response && error.response.status === 400 && error.response.data.reusedEmojis) {
            const reusedEmojiList = error.response.data.reusedEmojis
                .map((e: { emoji: string; date: string }) => {
                    const formattedDate = new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                    }).format(new Date(e.date));
                    return `${e.emoji} (used on ${formattedDate})`;
                })
                .join(", ");
            setErrorMessage(`Reused emoji${reusedEmojiList.length > 1 ? "s" : ""}: ${reusedEmojiList}`);
            toast.error("Some emojis have been used in the past. Please choose different emojis.");
        } else {
            console.error("Error updating emojis:", error);
            toast.error("An error occurred while updating the emojis.");
        }
    };

    const isSubmitDisabled = emojis.every((emoji) => emoji === "");

    return emojiSet ? (
        <DisplayEmojis nnextEmojis={nnextEmojis} />
    ) : (
        <EmojiInputForm
            emojis={emojis}
            inputError={inputError}
            handleEmojiChange={handleEmojiChange}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            errorMessage={errorMessage}
        />
    );
};

const DisplayEmojis: React.FC<{ nnextEmojis: string[] }> = ({ nnextEmojis }) => (
    <div className="flex flex-row items-center max-w-md mx-auto">
        <p className="text-gray-800 text-base m-2">
            <span className="font-bold text-indigo-500">(beta) </span>next AOTD emojis:
        </p>
        <div className="flex gap-2">
            {nnextEmojis.map((emoji, index) => emoji && (
                <span key={index} className="text-2xl">
                    {emoji}
                </span>
            ))}
        </div>
    </div>
);

const EmojiInputForm: React.FC<{
    emojis: string[];
    inputError: boolean[];
    handleEmojiChange: (index: number, value: string) => void;
    handleSubmit: () => void;
    isSubmitDisabled: boolean;
    errorMessage: string | null;
}> = ({ emojis, inputError, handleEmojiChange, handleSubmit, isSubmitDisabled, errorMessage }) => (
    <div className="flex flex-col m-2 border-4 border-gray-500 shadow-indigo-400 rounded-sm shadow items-center p-5 max-w-md mx-auto">
        <p className="text-gray-800 text-base m-2">
            <span className="font-bold text-indigo-500">(beta)</span> add your personal touch to your next AOTD
        </p>
        <small className="text-gray-400 mb-2 text-justify">
            these emojis will be used to create the next AOTD. mind that you cannot reuse the same emoji ever again.
        </small>
        <div className="flex gap-2">
            {emojis.map((emoji, index) => (
                <input
                    id={`emoji-input-${index}`}
                    key={index}
                    type="text"
                    value={emoji}
                    onChange={(e) => handleEmojiChange(index, e.target.value)}
                    placeholder="-"
                    className={`w-12 p-2 rounded border-2 text-center text-xl focus:outline-none 
            ${inputError[index]
                            ? "border-red-500 focus:border-red-600 border-spacing-4 animate-wiggle"
                            : "border-gray-300 focus:border-blue-500"
                        }`}
                />
            ))}
        </div>
        <BasicButton
            text="Set Emojis"
            onClick={handleSubmit}
            bgColor="bg-blue-500"
            hoverBgColor="hover:bg-blue-600"
            textColor="text-white"
            className="mt-4"
            disabled={isSubmitDisabled}
        />
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
    </div>
);

export default NextAOTD;

